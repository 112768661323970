import * as UI from "@goodgym/components";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRunOutlined";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivismOutlined";
import Counter from "../Counter";


export type MidBannerProps = {
  numberOfDeeds?: number;
  numberOfKm?: number;
  subtitleDeeds: string;
  subtitleKm: string;
};
const MidBanner: React.FC<MidBannerProps> = ({
  numberOfDeeds,
  numberOfKm,
  subtitleDeeds,
  subtitleKm
}) => {

  console.log("numberOfDeeds", numberOfDeeds);
  return (
    <>
      {" "}
      <UI.Container sx={{ mb: 2 }}>
        <UI.Typography
          variant="h1"
          sx={{
            mt: 7,
            fontStyle: "normal",
            mb: 2,
            textTransform: "uppercase",
            textAlign: "center",
            fontSize: "62px",
          }}
        >
          GoodGym Goal
        </UI.Typography>
        <UI.Typography variant="h1" sx={{ textAlign: "center" }}>
          <span style={{ color: "#E11018" }}>5000</span> good deeds
        </UI.Typography>
        <UI.Typography variant="h1" sx={{ textAlign: "center" }}>
          <span style={{ color: "#E11018" }}>65000</span> km
        </UI.Typography>
      </UI.Container>
      <UI.Box
        sx={{
          bgcolor: "black.main",
          minHeight: "300px",
          textAlign: "center",
          display: "flex",
          flexDirection: { xs: "column", md: "column" },
          justifyContent: "center",
          gap: "10px",
        }}
      >
        <UI.Typography
          variant="h1"
          color="white.main"
          sx={{
            mt: 4,
            fontStyle: "normal",
            mb: 1,
            textTransform: "uppercase",
            textAlign: "center",
            fontSize: "62px",
          }}
        >
          Live Target Counter
        </UI.Typography>
        <UI.Grid container sx={{ marginBottom: "30px", mt: { xs: 0, md: 5 } }}>
          <UI.Grid item xs={12} md={6} sx={{ mt: { xs: 3, md: 0 } }}>
            <VolunteerActivismIcon
              sx={{
                fontSize: "74px",
                color: "#ffffff",
                textAlign: "center",
              }}
            />
            {numberOfDeeds !== undefined && <Counter number={numberOfDeeds} />}
            <UI.Typography
              variant="h1"
              color="neutral.light"
              sx={{ textTransform: "uppercase" }}
            >
              {subtitleDeeds}
            </UI.Typography>
          </UI.Grid>
          <UI.Grid item xs={12} md={6} sx={{ mt: { xs: 3, md: 0 } }}>
            <DirectionsRunIcon sx={{ fontSize: "74px", color: "#ffffff" }} />
            {numberOfKm !== undefined && <Counter number={numberOfKm} />}
            <UI.Typography
              variant="h1"
              color="neutral.light"
              sx={{ textTransform: "uppercase" }}
            >
              {subtitleKm}
            </UI.Typography>
          </UI.Grid>
        </UI.Grid>
      </UI.Box>
    </>
  );
};

export default MidBanner;
