import * as UI from "@goodgym/components";
import { FaqData } from "@goodgym/pages/AboutPage/AboutCommunityMissionPage/data";
import * as C from "./components";
import { MissionBanner } from "./images";
import useJanChallengePage from "./components/useJanChallengePage";

const JanuaryChallengePage = () => {

  const { runner, runnerStats, areaStats, goodgymStats, dataSession, data } =
    useJanChallengePage();
  
  return (
    <UI.Box sx={{}}>
      <C.AboutChallenge runner={runner} />
      <UI.Container sx={{ my: 5, textAlign: "center" }}>
        <UI.Grid container>
          <UI.Grid item xs={12} md={runner ? 6 : 12}>
            <UI.Button
              variant="outlined"
              href="/v3/sessions"
              sx={{ fontSize: "30px", mb: { xs: 1, md: 0 } }}
            >
              SIGN UP TO A SESSION
            </UI.Button>
          </UI.Grid>
          {runner && (
            <UI.Grid item xs={12} md={6}>
              {" "}
              <UI.Button
                variant="outlined"
                sx={{ fontSize: "30px", alignItems: "center" }}
                href={`https://www.goodgym.org/runners/${runner.slug}/join-me/new`}
              >
                INVITE A FRIEND TO A SESSION
              </UI.Button>
            </UI.Grid>
          )}
        </UI.Grid>
      </UI.Container>
      {runnerStats && runner && <C.Stats runnerStats={runnerStats} />}

      <UI.Box sx={{ my: 5 }}>
        <C.AreaProgress
          areaStats={areaStats}
          goodgymStats={goodgymStats}
          runner={runner ? true : false}
        />
      </UI.Box>
      <C.MidBanner
        numberOfDeeds={data?.january.goodgymGoodDeeds}
        numberOfKm={data?.january.goodgymDistanceKm}
        subtitleDeeds="Good deeds completed"
        subtitleKm="KM completed"
      />

      <UI.Box
        sx={{
          backgroundImage: `url(${MissionBanner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: { xs: "300px", sm: "400px", md: "600px", lg: "800px" },
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      />

      <UI.Container>
        {data?.january.attendees?.length !== undefined && (
          <UI.Container
            sx={{
              mr: 2,
              mt: "4px",
              gap: "20px",
              overflow: "visible",
            }}
          >
            {" "}
            <UI.Typography variant="h4" sx={{ my: 4 }}>
              Recent Attendees
            </UI.Typography>
            <UI.Box
              sx={{
                display: "flex",
                gap: { xs: "5px", md: "10px" },
                flexWrap: "wrap",
              }}
            >
              {data?.january.attendees?.map((attendee, index) => (
                <C.AttendeesAvatar
                  sx={{ pr: "2px" }}
                  size="large"
                  key={index}
                  attendee={attendee}
                />
              ))}
            </UI.Box>
          </UI.Container>
        )}
      </UI.Container>

      <UI.Container sx={{ my: 5 }}>
        {dataSession && dataSession?.sessions?.results.length > 0 && (
          <>
            <UI.Typography variant="h4" sx={{ my: 2 }}>
              Sessions near you
            </UI.Typography>
            <UI.Grid container spacing={3}>
              {dataSession?.sessions.results
                .slice(0, 4)
                .map((session, index) => (
                  <UI.Grid key={index} item xs={12} md={6}>
                    <UI.ListingCard january={true} listing={session} />
                  </UI.Grid>
                ))}
            </UI.Grid>{" "}
          </>
        )}
      </UI.Container>
      <UI.Container sx={{ my: 3, mb: 8 }}>
        <C.FAQ info={FaqData} />
      </UI.Container>
    </UI.Box>
  );
};

export default JanuaryChallengePage;
