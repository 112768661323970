import * as UI from "@goodgym/components";

export type FAQProp = {
  info: { heading?: string; content?: string }[];
};

const FAQ: React.FC<FAQProp> = ({ info }) => {
  return (
    <UI.Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <UI.Typography variant="h3" sx={{ mt: 2 }}>
        Frequently Asked Questions
      </UI.Typography>
      <UI.Grid container spacing={3} sx={{ mt: 1, mb:2 }}>
        {info.map((element, index) => (
          <UI.Grid item xs={12} md={4} key={index}>
            <UI.Typography variant="subtitle1" sx={{ mb: 2 }}>
              {element.heading}
            </UI.Typography>
            <UI.Typography>{element.content}</UI.Typography>
          </UI.Grid>
        ))}
      </UI.Grid>
      <UI.Typography>
        If you have any more questions please visit our{" "}
        <UI.Link href={"https://goodgym.kayako.com/"}>FAQ</UI.Link> page
      </UI.Typography>
    </UI.Container>
  );
};

export default FAQ;
