/** @jsxImportSource @emotion/react */
import * as UI from "@goodgym/components";
import * as u from "@goodgym/util";
import React from "react";

export type AttendeesAvatarProps = UI.AvatarProps & {
  attendee: {
    __typename?: "Runner";
    id: string;
    name: string;
    slug: string;
    bio: string;
    avatarUrl: string;
  };
  size?: u.AvatarSize;

};

const AttendeesAvatar: React.FC<AttendeesAvatarProps> = React.forwardRef(
  ({ attendee, size = "medium", ...props }, ref) => {
    const sx = props.sx || ({} as any);

    if (
      u.isDev &&
      !!["m", "my", "mx", "mt", "mb", "mr", "ml"].find((p) => sx[p])
    )
      throw new Error(
        "Margin not supported on RunnerAvatar, wrap in a Box instead"
      );

    const height = u.avatarSize(size);

    if (attendee.slug === "hidden") {
      return (
        <UI.Tooltip
          title={<UI.Typography variant="body1">{attendee.name}</UI.Typography>}
        >
          <UI.Avatar
            alt={attendee.name}
            ref={ref}
            sx={{
              height,
              width: height,
              ...props.sx,
            }}
         />
        </UI.Tooltip>
      );
    }

    const avatar = (
      <UI.Avatar
        {...(props as any)}
        component={UI.Link}
        ref={ref}
        to={`/v3/profile/${attendee.slug}`}
        alt={attendee.name}
        src={attendee.avatarUrl}
        sx={{
          height,
          width: height,
          ...props.sx,
        }}
      />
    );

    return (
      <UI.MuiBadge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        {avatar}
      </UI.MuiBadge>
    );
  }
);

export default AttendeesAvatar;
