/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import { useAuth } from "@goodgym/auth";

export type RunnerAvatarProps = UI.AvatarProps & {
  runner: Omit<T.RunnerFragment, "__typename">;
  size?: u.AvatarSize;
  noStatus?: boolean;
  
};

const RunnerAvatar: React.FC<RunnerAvatarProps> = React.forwardRef(
  ({ runner, size = "medium", noStatus = false, ...props }, ref) => {
    const sx = props.sx || ({} as any);

    // this is really really annoying but setting a margin on the avatar
    // breaks the status badge, so we make sure we don't accidentally
    // do this without realising
    if (
      u.isDev &&
      !!["m", "my", "mx", "mt", "mb", "mr", "ml"].find((p) => sx[p])
    )
      throw new Error(
        "Margin not supported on RunnerAvatar, wrap in a Box instead"
      );

    const { runner: currentRunner } = useAuth();

    const height = u.avatarSize(size);

    if (runner.slug === "hidden") {
      return (
        <UI.Tooltip
          title={<UI.Typography variant="body1">{runner.name}</UI.Typography>}
        >
          <UI.Avatar
            alt={runner.name}
            ref={ref}
            sx={{
              height,
              width: height,
              ...props.sx,
            }}
          >
            <Icons.DirectionsRun
              sx={{ height: height - 4, width: height - 4 }}
            />
          </UI.Avatar>
        </UI.Tooltip>
      );
    }

    const avatar = (
      <UI.Avatar
        {...(props as any)}
        component={UI.Link}
        ref={ref}
        to={u.links.profile(runner)}
        alt={runner.name}
        src={runner.avatarUrl}
        sx={{
          height,
          width: height,
          ...props.sx,
        }}
      />
    );

    const icon = u.status.icon(runner, currentRunner);

    if (noStatus || !icon) {
      return <UI.RunnerTooltip runner={runner}>{avatar}</UI.RunnerTooltip>;
    } else {
      return (
        <UI.RunnerTooltip runner={runner}>
          <UI.MuiBadge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <UI.Avatar
                sx={{
                  width: "22px",
                  height: "22px",
                  ...u.status.color(runner, currentRunner),
                }}
              >
                {icon}
              </UI.Avatar>
            }
          >
            {avatar}
          </UI.MuiBadge>
        </UI.RunnerTooltip>
      );
    }
  }
);

// @ts-ignore
RunnerAvatar.muiName = UI.Avatar.muiName;

export default RunnerAvatar;
