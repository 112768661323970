import * as UI from "@goodgym/components";
import { BadgeMission } from "../../images";


const GetInvolvedCard = ({
}) => {
  return (
    <UI.Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
        mt: "-300px",
      }}
    >
      <UI.Card
        sx={{
          width: { xs: "80%", sm: "65%", md: "60%" },
          borderRadius: "10px",
        }}
      >
        <UI.CardHeader
          title={
            <UI.Typography
              variant="h2"
              sx={{
                fontStyle: "italic",
                textTransform: "uppercase",
                textAlign: "center",
              }}
            >
              Get involved
            </UI.Typography>
          }
        />
        <UI.CardContent sx={{ mt: -1 }}>
          <UI.Typography sx={{ mb: 2, textAlign:"center", fontWeight:"normal" }} variant="h5">
            Join GoodGym so you can start doing missions. It's simple and we'll
            guide you through every step.
          </UI.Typography>
          <UI.Box
            sx={{
              width: "110%",
              borderTop: "10px solid #E11018",
              ml: -2,
            }}
          />
          <UI.Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src={BadgeMission}
              alt="badge"
              style={{ height: "120px", width: "auto", marginTop:"20px", marginBottom:"10px" }}
            />
            <UI.Typography variant="h4" sx={{ mb: 1, textAlign: "center" }}>
              The process
            </UI.Typography>
            <UI.Typography variant="body1" sx={{ textAlign: "center", my: 1 }}>
              You can be ready for your first mission in less than two weeks.
              Your next steps are to:
            </UI.Typography>
            <UI.Typography variant="body2" sx={{  my: 1, mb:2 }}>
              <li>Sign up</li>
              <li>Complete a background check</li>
              <li>Do a short online training module so that you're prepared</li>
              <li>Book a short video call with GoodGym’s representative</li>
              <li>Choose your first mission</li>
            </UI.Typography>
            <UI.Button
              href={"https://www.goodgym.org/my/missions/verification"}
            >
              Get mission Verified
            </UI.Button>
          </UI.Box>
        </UI.CardContent>
      </UI.Card>
    </UI.Box>
  );
};

export default GetInvolvedCard;
