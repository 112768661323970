import * as Images from "../images";
import * as Icons from "../../images";
import Looks3RoundedIcon from "@mui/icons-material/Looks3Rounded";
import LooksOneRoundedIcon from "@mui/icons-material/LooksOneRounded";
import LooksTwoRoundedIcon from "@mui/icons-material/LooksTwoRounded";


export const taskIconData = [
  {
    image: Icons.ideaIcon,
    tagline:
      "Change a lightbulb for an older person so that they can see in the dark",
  },
  {
    image: Icons.PlantIcon,
    tagline:
      "Do some gardening so that an older person can feel independent again",
  },
  {
    image: Icons.liftingIcon,
    tagline:
      "Move furniture so that an older person can get their home back in order",
  },
];


export const missionTasks = [
  {
    title: "Get there",
    image: Icons.RunningIcon,
    tagline:
      "Run, walk or cycle solo from where you are to get to the older person's home.",
    icon: LooksOneRoundedIcon,
  },
  {
    title: "Help out",
    image: Icons.HoldingBannerIcon,
    tagline: "Go solo or meet other GoodGymers to complete the task.",
    icon: LooksTwoRoundedIcon,
  },
  {
    title: "Head back",
    image: Icons.RunningIcon,
    tagline: "Run, walk or cycle your return route.",
    icon: Looks3RoundedIcon,
  },
];


export const whyHelp = [
  {
    image: Icons.ShoeIcon,
    tagline: "Increase your motivation to exercise",
  },
  {
    image: Icons.IconHeart,
    tagline: "Help make your local area a better place to live",
  },
  {
    image: Icons.clockIcon,
    tagline: "Fits around your schedule",
  },
];

export const RunnersTestimonials = [
  {
    avatarUrl: Images.Hazel,
    quote:
      "Before GoodGym I always lost motivation to stick at running or the gym but now I run two or three times a week.",
    by: "Hazel, GoodGym Barnet",
  },
  {
    avatarUrl: Images.Mark,
    quote:
      "Running with GoodGym is great because I get fit, do good and get to hang with a load of awesome people.",
    by: "Mark, GoodGym Islington",
  },
  {
    avatarUrl: Images.Judy,
    quote:
      "GoodGym combines doing good with running, which helps me forget I'm even exercising. It's great!",
    by: "Judy, GoodGym LambethM",
  },
];

export const FaqData = [
  {
    heading: "How much does it cost?",
    content:
      "GoodGym is free. Most of us make a monthly donation to help cover the cost of running our charity, but no one has to.",
  },
  {
    heading: "How do I get started?",
    content:
      "We'll need you to do a background check, an online training module and a short video call with a member of our team so that you're prepared for your missions. This takes less than two hours over 2 weeks.",
  },
  {
    heading: "What commitment is expected?",
    content:
      "You can run as many missions as you want. We organise missions usually after work and on the weekends, so you can fit them around your schedule.",
  },
];
