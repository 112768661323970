import * as UI from "@goodgym/components";
import SessionSignupDialog from "@goodgym/components/SessionSignupDialog";
import { useSessionPageQuery } from "@goodgym/graphql/hooks";
import * as Icons from "@goodgym/icons";
import * as u from "@goodgym/util";
import React from "react";
import { useParams } from "react-router-dom";
import Testimonial1 from "./testimonial1.png";
import Testimonial2 from "./testimonial2.png";
import * as C from "../SessionPage/components";
import { useTheme } from "@goodgym/components";
import LocationCard from "./components/LocationCard";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const testimonials = [
  {
    quote:
      "GoodGym is a wonderful initiative. It combines three of the most powerful ways of uplifting your own wellbeing – exercising, socialising, and, above all, doing good for others",
    name: "Professor Lord Richard",
    image: Testimonial1,
  },
  {
    quote:
      "All of our tasks are fun, but this one really felt like we were having a clear and direct impact on those in need, and whilst packing the bags we could literally imagine a child opening their bag on Christmas Day.",
    name: "Rachel",
    image: Testimonial2,
  },
];

const SignUpPage = () => {
  const { slug } = useParams();
  const { data } = useSessionPageQuery({
    variables: { slug: slug! },
    skip: !slug,
  });

  const theme = useTheme();
  const md = UI.useMediaQuery(theme.breakpoints.up("md"));
  const [showComponent, setShowComponent] = React.useState(false);

  const showSignupModal = () => setShowComponent(true);
  const hideSignupModal = () => setShowComponent(false);

  if (!data) return <UI.Loading />;
  const { session } = data;

  return (
    <UI.Box sx={{ }}>
      <UI.Box
        sx={{
          display: "flex",
          mt: 1,
          mx: { xs: 1, md: 0 },
          mr: 4,
          mb: { xs: 0, sm: 1, md: 2 },
 
        }}
      >
        <UI.Box sx={{ width: "40%", pl:4}}>
          <UI.Logo height={60} />
        </UI.Box>

        <UI.Box sx={{ width: "60%" , textAlign: "center"}}>
          {" "}
          {session.organisation && (
            <UI.Typography
              variant="h1"
              sx={{
                mb: 3,
                mt: 2,
                px: 1,
                fontStyle: "normal",
                fontFamily: "'Barlow Condensed', sans-serif",
                textTransform: "uppercase",

                fontSize: { xs: "30px", md: "40px" },
              }}
              color="primary"
            >
              📌 {session.organisation?.name}
            </UI.Typography>
          )}
        </UI.Box>
      </UI.Box>

      <UI.Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          mt: { md: 4 },
        }}
      >
        <UI.Box
          sx={{
            mx: { xs: 0, md: 2 },
            width: { xs: "100%", md: "40%" },
            overflow: { xs: "visible", md: "hidden" },
            height: { xs: "auto", md: "auto" },

            top: { xs: "auto", md: "0" },
            textAlign: "center",
          }}
        >
          <UI.Container>
            <UI.Box
              component="img"
              src={session.imageUrl}
              sx={{
                width: { xs: "auto", md: "auto" },
                maxWidth: "100%",
                height: { xs: "450px", md: "590px" },
                borderRadius: "10px",
                objectFit: "cover",
                mt: 1,
              }}
            />
          </UI.Container>

          <UI.Container
            sx={{
              my: 3,
              width: { xs: "100%", md: "100%" },
              textAlign: "center",
              display: { xs: "none", md: "block" },
            }}
          >
            <UI.Button
              fullWidth
              onClick={showSignupModal}
              sx={{
                fontSize: "34px",
              }}
            >
              Join this open session
            </UI.Button>
            {showComponent && (
              <SessionSignupDialog
                onClose={hideSignupModal}
                open={showComponent}
                session={session}
              />
            )}
          </UI.Container>
          <UI.Container sx={{ my: 1, display: { xs: "none", md: "block" } }}>
            <UI.Grid container spacing={3} sx={{}}>
              {testimonials.map((quote, index) => (
                <UI.Grid item xs={12} md={6} key={index}>
                  <UI.Card sx={{ height: { md: "500px", lg: "360px" } }}>
                    <UI.Box
                      sx={{
                        borderTop: "4px solid #E11018",
                      }}
                    />
                    <UI.Box
                      sx={{
                        p: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <UI.Avatar
                        src={quote.image}
                        sx={{ height: "120px", width: "120px" }}
                      />
                      <UI.Box sx={{ display: "flex" }}>
                        <UI.Typography variant="h5" sx={{ mr: 1, mt: 1 }}>
                          ||
                        </UI.Typography>
                        <UI.Typography variant="body1" sx={{ mt: 2 }}>
                          {quote.quote}
                        </UI.Typography>
                      </UI.Box>

                      <UI.Typography
                        variant="body1"
                        color={"primary.main"}
                        sx={{ mt: 2, textAlign: "right" }}
                      >
                        {quote.name}
                      </UI.Typography>
                    </UI.Box>
                  </UI.Card>
                </UI.Grid>
              ))}
            </UI.Grid>
          </UI.Container>
        </UI.Box>
        <UI.Box
          sx={{
            width: { xs: "100%", md: "60%" },
            // overflowY: { xs: "visible", md: "auto" },
            // height: { xs: "auto", md: "auto" },
            mx: 0,
            px: 2,
            mb: "120px",
          }}
        >
          <UI.Box
            sx={{
              minHeight: { xs: "auto", md: "500px" },
              textAlign: { xs: "center", md: "left" },
            }}
          >
            <UI.Typography
              variant="h1"
              sx={{
                mb: 0.5,
                px: 0,
                fontStyle: "normal",
                fontFamily: "'Barlow Condensed', sans-serif",
                textTransform: "uppercase",
                fontSize: { xs: "40px", md: "70px" },
              }}
            >
              {session.title}
            </UI.Typography>

            <UI.Typography
              variant="subtitle1"
              sx={{
                mt: 1,
                mb: 2,
                px: 0,
                fontStyle: "italic",
                fontFamily: "'Barlow Condensed', sans-serif",
                fontSize: { xs: "20px", md: "30px" },
              }}
            >
              {session.strapline}
            </UI.Typography>

            <UI.Box sx={{ textAlign: "left", display: "flex" }}>
              <Icons.CalendarToday
                sx={{
                  fontSize: { xs: "50px", md: "70px" },
                  pr: 1,
                  mt: 3,
                }}
              />
              <UI.Box>
                {" "}
                <UI.Typography
                  variant="h2"
                  sx={{
                    mb: 0.5,
                    px: { xs: 0, md: 0.5 },
                    fontStyle: "normal",
                    fontFamily: "'Barlow Condensed', sans-serif",
                    textTransform: "uppercase",
                    mt: 3,
                    fontSize: { xs: "30px", md: "34px" },
                  }}
                >
                  {u.time.format(session.startedAt, "EEEE do MMMM yyyy")}
                </UI.Typography>
                <UI.Typography
                  variant="h4"
                  sx={{
                    mb: 0.5,
                    px: 0.5,
                    fontStyle: "normal",
                    fontFamily: "'Barlow Condensed', sans-serif",
                    textTransform: "uppercase",
                    color: "#F01E28",
                  }}
                >
                  {u.time.formatTimeRange(session.startedAt, session.duration)}
                </UI.Typography>
              </UI.Box>
            </UI.Box>
            <UI.Box sx={{ display: "flex" }}>
              <Icons.LocationOn
                sx={{
                  verticalAlign: "middle",
                  fontSize: "40px",
                  mt: 3,
                  ml: 1.5,
                }}
              />
              <UI.Typography variant="h4" sx={{ mt: 4, ml: 2 }}>
                {session.area?.name}
              </UI.Typography>
            </UI.Box>
            {session.signups.length > 0 && (
              <UI.Box sx={{ textAlign: "left", mt: 3 }}>
                <C.Attendees easySignup={true} session={session} sx={{}} />
              </UI.Box>
            )}
          </UI.Box>

          {session.address && (
            <>
              <UI.Card sx={{ mb: 2, px: 2 }}>
                <UI.Typography variant="h4" sx={{ mt: 1, mb: 1 }}>
                  About the task
                </UI.Typography>

                <UI.Typography variant="body1" sx={{ fontSize: "16px" }}>
                  <UI.Box
                    className="gg-markdown"
                    dangerouslySetInnerHTML={{
                      __html: session.description,
                    }}
                  />
                </UI.Typography>
              </UI.Card>

              <LocationCard session={session} />

              <UI.Box>
                {session.organisation && (
                  <UI.Accordion sx={{ mb: 2 }}>
                    <UI.AccordionSummary
                      expandIcon={
                        <Icons.ExpandMore sx={{ fontSize: "24px" }} />
                      }
                      aria-controls="this-task-supports-content"
                      id="this-task-supports-header"
                    >
                      <UI.Typography variant="h4" sx={{ my: 1 }}>
                        This task supports{" "}
                        {session.organisation &&
                          `the organisation ${session.organisation.name}`}
                      </UI.Typography>
                    </UI.AccordionSummary>
                    <UI.AccordionDetails>
                      <UI.OrganisationCard
                        organisation={session.organisation}
                      />
                    </UI.AccordionDetails>
                  </UI.Accordion>
                )}
                <UI.Accordion sx={{ mb: 2 }}>
                  <UI.AccordionSummary
                    expandIcon={<Icons.ExpandMore sx={{ fontSize: "24px" }} />}
                    aria-controls="what-to-look-out-for-content"
                    id="what-to-look-out-for-header"
                  >
                    <UI.Typography variant="h4" sx={{ my: 1 }}>
                      What to look out for
                    </UI.Typography>
                  </UI.AccordionSummary>
                  <UI.AccordionDetails>
                    <C.WhatToLookOutFor
                      session={session}
                      easySignup={true}
                      sx={{ my: 3 }}
                    />
                  </UI.AccordionDetails>
                </UI.Accordion>

                {!md && (
                  <UI.Card sx={{ my: 1, display: { xs: "block", md: "none" } }}>
                    <UI.Grid container spacing={3} sx={{}}>
                      {testimonials.map((quote, index) => (
                        <UI.Grid item xs={12} md={6} key={index}>
                          <UI.Card
                            sx={{ height: { md: "480px", lg: "330px" } }}
                          >
                            <UI.Box
                              sx={{
                                borderTop: "4px solid #E11018",
                              }}
                            />
                            <UI.Box
                              sx={{
                                p: 1,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                              }}
                            >
                              <UI.Avatar
                                src={quote.image}
                                sx={{ height: "120px", width: "120px" }}
                              />
                              <UI.Box sx={{ display: "flex" }}>
                                <UI.Typography
                                  variant="h5"
                                  sx={{ mr: 1, mt: 1 }}
                                >
                                  ||
                                </UI.Typography>
                                <UI.Typography variant="body1" sx={{ mt: 2 }}>
                                  {quote.quote}
                                </UI.Typography>
                              </UI.Box>

                              <UI.Typography
                                variant="body1"
                                color={"primary.main"}
                                sx={{ mt: 2, textAlign: "right" }}
                              >
                                {quote.name}
                              </UI.Typography>
                            </UI.Box>
                          </UI.Card>
                        </UI.Grid>
                      ))}
                    </UI.Grid>
                  </UI.Card>
                )}
                <UI.Accordion sx={{ mb: 2 }}>
                  <UI.AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ fontSize: "24px" }} />}
                    aria-controls="what-to-look-out-for-content"
                    id="what-to-look-out-for-header"
                  >
                    <UI.Typography variant="h4" sx={{ my: 1 }}>
                      About GoodGym
                    </UI.Typography>
                  </UI.AccordionSummary>
                  <UI.AccordionDetails>
                    <UI.Typography variant="body1">
                      GoodGym is a community of people that get fit by doing
                      good. We run, walk and cycle to do practical tasks for
                      community organisations and isolated older people.
                    </UI.Typography>
                  </UI.AccordionDetails>
                </UI.Accordion>
              </UI.Box>
            </>
          )}
        </UI.Box>
      </UI.Box>
      <UI.Box
        sx={{
          my: 3,
          width: { xs: "100%", md: "100%" },
          textAlign: "center",
          display: { xs: "block", md: "none" },
          mb: "56px",
          position: "fixed",
          bottom: 0,
          zIndex: 999,
        }}
      >
        <UI.Button
          fullWidth
          onClick={showSignupModal}
          sx={{
            fontSize: "24px",
          }}
        >
          Join this open session
        </UI.Button>
        {showComponent && (
          <SessionSignupDialog
            onClose={hideSignupModal}
            open={showComponent}
            session={session}
          />
        )}
      </UI.Box>
    </UI.Box>
  );
};

export default SignUpPage;
