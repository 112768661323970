/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import { useRunnerTooltipLazyQuery } from "@goodgym/graphql/hooks";

export type RunnerTooltipProps = Omit<UI.TooltipProps, "title"> & {
  runner: T.RunnerFragment | T.CurrentRunnerFragment | { __typename?: "Runner" | undefined; id: string; name: string; slug: string; bio: string; avatarUrl: string; };
};

const RunnerTooltip: React.FC<RunnerTooltipProps> = ({
  runner: { id },
  ...props
}) => {
  const [fetchRunnerDetails, { data }] = useRunnerTooltipLazyQuery({
    variables: { id },
    fetchPolicy: "cache-first",
  });

  const runner = data?.runner;

  return (
    <UI.Tooltip
      onOpen={() => fetchRunnerDetails()}
      enterDelay={500}
      leaveDelay={200}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: "#fff",
            boxShadow: "0 0 8px rgba(0, 0, 0, 0.22)",
            width: "220px",
            m: 1,
          },
        },
      }}
      title={runner ? <UI.RunnerCard runner={runner} /> : <UI.Loading />}
      {...props}
    />
  );
};

// @ts-ignore
RunnerTooltip.muiName = UI.Tooltip.muiName;
export default RunnerTooltip;
