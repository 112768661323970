import * as UI from "@goodgym/components";
import React from "react";

export type StatsProp = {
  runnerStats: {
    runnerPledgeDeeds: number;
    runnerPledgeKm: number;
    runnerCompletedDeeds: number;
    runnerCompletedKm: number;
  };
};
const Stats: React.FC<StatsProp> = ({ runnerStats }) => {
  const [deedsWidth, setDeedsWidth] = React.useState(0);
  const [kmWidth, setKmWidth] = React.useState(0);
  const [isVisible, setIsVisible] = React.useState(false);
  const containerRef = React.useRef(null);

  const percentageDeeds = React.useCallback(() => {
    if (
      runnerStats.runnerCompletedDeeds === 0 ||
      runnerStats.runnerPledgeDeeds === 0
    )
      return 0;
    else
      return Math.round(
        (runnerStats.runnerCompletedDeeds / runnerStats.runnerPledgeDeeds) * 100
      );
  }, [runnerStats]);

  const percentageKm = React.useCallback(() => {
    if (runnerStats.runnerCompletedKm === 0 || runnerStats.runnerPledgeKm === 0)
      return 0;
    else
      return Math.round(
        (runnerStats.runnerCompletedKm / runnerStats.runnerPledgeKm) * 100
      );
  }, [runnerStats]);

  React.useEffect(() => {
    const currentRef = containerRef.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.02 }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  React.useEffect(() => {
    if (isVisible) {
      const interval = setInterval(() => {
        setDeedsWidth((prevWidth) => {
          const targetWidth = Math.min(percentageDeeds(), 100);
          if (prevWidth >= targetWidth) {
            clearInterval(interval);
            return targetWidth;
          }
          return prevWidth + 10;
        });
      }, 100);

      const intervalKm = setInterval(() => {
        setKmWidth((prevWidth) => {
          const targetWidth = Math.min(percentageKm(), 100);
          if (prevWidth >= targetWidth) {
            clearInterval(intervalKm);
            return targetWidth;
          }
          return prevWidth + 10;
        });
      }, 100);

      return () => {
        clearInterval(interval);
        clearInterval(intervalKm);
      };
    }
  }, [isVisible, percentageDeeds, percentageKm]); 

  return (
    <>
      <UI.Container>
        <UI.Typography variant="h2" sx={{ textAlign: "center", mb: 3 }}>
          Your Progress
        </UI.Typography>
        <UI.Grid container>
          <UI.Grid item xs={12} md={6}>
            <UI.Box
              ref={containerRef}
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <UI.Typography variant="h3" sx={{ mt: 3 }}>
                {runnerStats.runnerCompletedDeeds} Good Deeds Completed
              </UI.Typography>
              <UI.Container sx={{ textAlign: "center", my: 5 }}>
                <UI.Box
                  sx={{
                    width: "100%",
                    backgroundColor: "#000000",
                    borderRadius: "30px",
                  }}
                >
                  <UI.Box
                    sx={{
                      width: `${deedsWidth}%`,
                      height: "80px",
                      backgroundColor: "#F01E28",
                      borderTopLeftRadius: "20px",
                      borderBottomLeftRadius: "20px",
                      transition: "width 1s ease-in-out",
                      borderTopRightRadius: "20px",
                      borderBottomRightRadius: "20px",
                    }}
                  />
                </UI.Box>

                <UI.Typography
                  variant="h5"
                  sx={{ mt: 1, textAlign: "center", ml: 1 }}
                >
                  Good deeds pledged: {runnerStats.runnerPledgeDeeds}
                </UI.Typography>
              </UI.Container>
            </UI.Box>
          </UI.Grid>
          <UI.Grid item xs={12} md={6}>
            <UI.Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <UI.Typography variant="h3" sx={{ mt: 3 }}>
                {runnerStats.runnerCompletedKm} KM Completed
              </UI.Typography>
              <UI.Container sx={{ textAlign: "center", my: 5 }}>
                <UI.Box
                  sx={{
                    width: "100%",
                    backgroundColor: "#000000",
                    borderRadius: "30px",
                  }}
                >
                  <UI.Box
                    sx={{
                      width: `${kmWidth}%`,
                      height: "80px",
                      backgroundColor: "#F01E28",
                      borderTopLeftRadius: "20px",
                      borderBottomLeftRadius: "20px",
                      transition: "width 1s ease-in-out",
                      borderTopRightRadius: "20px",
                      borderBottomRightRadius: "20px",
                    }}
                  />
                </UI.Box>

                <UI.Typography
                  variant="h5"
                  sx={{ mt: 1, textAlign: "center", ml: 1 }}
                >
                  Km Pledged: {runnerStats.runnerPledgeKm}km
                </UI.Typography>
              </UI.Container>
            </UI.Box>
          </UI.Grid>
        </UI.Grid>
      </UI.Container>
    </>
  );
};

export default Stats;
