/** @jsxImportSource @emotion/react */
import * as auth from "@goodgym/auth";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as Icons from "@goodgym/icons";
import * as u from "@goodgym/util";
import * as C from "./components";
import * as images from "./images";
const HomePage = () => {
  const { isLoggedIn } = auth.useAuth();
  const { data } = T.useHomePageQuery();

  console.log("data", data?.stories.stories
)

  if (isLoggedIn) return <UI.Reload href="/v3" />;

  return (
    <>
      <UI.Seo
        title={u.seo.title}
        description={u.seo.description}
        image={images.landing.jpg}
        url="/"
      />

      <UI.Navbar />
      <UI.Container
        sx={{
          pb: { xs: 3, md: 3 },
          overflow: "hidden",
        }}
      >
        <UI.CoverImage
          image={images.landing}
          sx={{ mx: { xs: -2, sm: -3, md: 0 }, mb: 2 }}
        />
        <C.JoinUs sx={{ mt: { xs: 6, lg: 4 }, mb: 3 }} />
      </UI.Container>
      <UI.Box sx={{ pb: { xs: 1, md: 3 }, backgroundColor: "neutral.light" }}>
        <UI.Container sx={{ overflow: "hidden" }}>
          <UI.Grid container columnSpacing={5}>
            <UI.Grid
              item
              mb={2}
              xs={12}
              md={6}
              alignContent="top"
              justifyContent="left"
              sx={{ pt: { xs: 0, md: 6 } }}
            >
              <UI.CoverImage
                image={images.group}
                sx={{ mx: { xs: -2, sm: -3, md: 0 } }}
              />
            </UI.Grid>
            <UI.Grid item xs={12} md={6} sx={{ pt: 6 }}>
              <UI.Typography variant="h2" sx={{ mb: 2 }}>
                We{" "}
                <UI.Typography
                  variant="h2"
                  component="span"
                  color="secondary.dark"
                >
                  run
                </UI.Typography>
                ,{" "}
                <UI.Typography
                  variant="h2"
                  component="span"
                  color="secondary.dark"
                >
                  walk
                </UI.Typography>
                , and{" "}
                <UI.Typography
                  variant="h2"
                  component="span"
                  color="secondary.dark"
                >
                  cycle
                </UI.Typography>{" "}
                to help local{" "}
                <UI.Typography
                  variant="h2"
                  component="span"
                  color="secondary.dark"
                >
                  community organisations
                </UI.Typography>{" "}
                and{" "}
                <UI.Typography
                  variant="h2"
                  component="span"
                  color="secondary.dark"
                >
                  isolated older people
                </UI.Typography>{" "}
                by doing practical tasks.
              </UI.Typography>
            </UI.Grid>
            <UI.Grid item xs={12}>
              <C.WhatWeDo />
            </UI.Grid>
          </UI.Grid>
        </UI.Container>
      </UI.Box>
      <UI.Box sx={{ py: { xs: 3, md: 6 }, backgroundColor: "#961E32" }}>
        <UI.Container sx={{ overflow: "hidden" }}>
          <UI.Grid container columnSpacing={5}>
            <UI.Grid item xs={12} md={6} sx={{ mb: 2 }}>
              <C.Stats stats={data?.stats} />
              <C.PostcodeSearch
                fullWidth
                sx={{ display: { xs: "block", sm: "none" } }}
              />
              <C.PostcodeSearch sx={{ display: { xs: "none", sm: "block" } }} />
            </UI.Grid>
            <UI.Grid item xs={12} md={6} sx={{ mb: 2, textAlign: "center" }}>
              <images.Map css={{ maxHeight: "600px", maxWidth: "100%" }} />
            </UI.Grid>
          </UI.Grid>
        </UI.Container>
      </UI.Box>
      <UI.Box
        sx={{
          pt: { xs: 3, md: 6 },
          pb: { xs: 0, md: 3 },
          backgroundColor: "neutral.light",
        }}
      >
        <UI.Container sx={{ overflow: "hidden" }}>
          <UI.Grid container columnSpacing={5}>
            <UI.Grid item xs={12}>
              <UI.Typography variant="h1">Why join?</UI.Typography>
            </UI.Grid>
            <UI.Grid item xs={12} md={6} sx={{ mb: 2 }}>
              <C.WhyJoin />
            </UI.Grid>
            <UI.Grid item xs={12} md={6} sx={{ mb: 2 }}>
              <C.Benefits />
            </UI.Grid>
          </UI.Grid>
        </UI.Container>
      </UI.Box>
      <UI.Box
        sx={{
          pt: { xs: 0, md: 6 },
          pb: { xs: 0, md: 3 },
          backgroundColor: "white",
        }}
      >
        <UI.Container sx={{ overflow: "hidden" }}>
          <UI.Grid container columnSpacing={5}>
            <UI.Grid item xs={12} lg={6} sx={{ mb: 2 }}>
              <UI.CoverImage
                image={images.socialising}
                sx={{ mx: { xs: -2, sm: -3, md: 0 } }}
              />
            </UI.Grid>
            <UI.Grid item xs={12} lg={6} sx={{ mb: 2 }}>
              <C.WhatDoesItCost />
            </UI.Grid>
          </UI.Grid>
        </UI.Container>
      </UI.Box>

      <UI.Box sx={{ py: { xs: 3, md: 6 }, backgroundColor: "neutral.light" }}>
        <UI.Container sx={{ overflow: "hidden" }}>
          <C.Testimonials />
        </UI.Container>
      </UI.Box>
      <UI.Container sx={{ my: 3 }}>
        <UI.Typography variant="h1" sx={{ my: 3 }}>
          What GoodGym Says
        </UI.Typography>
        <UI.Grid container spacing={3}>
          {data?.stories.stories.slice(0, 3).map((story) => (
            <UI.Grid key={story.id} item xs={12} md={4} xl={4}>
              <UI.Card
                key={story.id}
                sx={{ height: "100%", alignItems: "center" }}
              >
                <UI.CardMedia image={story.imageUrl} title={story.title} />
                <UI.CardContent>
                  <UI.AreaChip area={story.area} sx={{ mr: 1, mb: 1 }} />
                  <UI.Typography gutterBottom variant="h5" component="h2">
                    <UI.Link href={u.links.story(story)} color="black.main">
                      {story.title}
                    </UI.Link>
                  </UI.Typography>
                  <UI.Typography
                    color="textSecondary"
                    variant="subtitle2"
                    sx={{ my: 1 }}
                  >
                    {story.discardedAt ? (
                      <>
                        <Icons.Delete sx={{ fontSize: "20px", mr: 1 }} />
                        {u.time.formatDate(story.discardedAt)}
                      </>
                    ) : (
                      story.publishedAt && (
                        <>
                          <Icons.EventRounded
                            sx={{ fontSize: "20px", mr: 1 }}
                          />
                          {u.time.formatDate(story.publishedAt)}
                        </>
                      )
                    )}
                  </UI.Typography>
                  {story.author && (
                    <UI.Typography
                      color="textSecondary"
                      variant="subtitle2"
                      sx={{ my: 1 }}
                    >
                      <Icons.ArticleRounded sx={{ fontSize: "20px", mr: 1 }} />
                      Story written by <UI.RunnerLink runner={story.author} />
                    </UI.Typography>
                  )}

                  <UI.Typography sx={{ mt: 2 }}>
                    {story.strapline}
                  </UI.Typography>
                  <UI.Button
                    href={u.links.story(story)}
                    variant="text"
                    sx={{ ml: -1, color: "secondary.dark" }}
                  >
                    Read more
                  </UI.Button>
                </UI.CardContent>
              </UI.Card>
            </UI.Grid>
          ))}
        </UI.Grid>
        <UI.Box sx={{ textAlign: "right", my:3, fontSize:"24px" }}>
          <UI.Link href={u.links.stories()}>Read all stories</UI.Link>
        </UI.Box>
      </UI.Container>
      <UI.Footer />
    </>
  );
};

export default HomePage;
